import axiosIns from '@/libs/axios'
import utilsService from './utilsService'

const controller = 'user'

export default {
  async getAll(filter) {
    return axiosIns
      .get(`${controller}?${utilsService.getQueryParams(filter)}`)
      .then(res => res.data)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async getByLogin(login) {
    return axiosIns.get(`${controller}?login=${login}`).then(res => res.data.content[0])
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async approveStudent(id, approved) {
    return axiosIns.patch(`${controller}/${id}`, { approved }).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
  async changePassword(req) {
    return axiosIns.put(`${controller}/change-password`, req).then(res => res.data)
  },
  async obterUserScore(req) {
    return axiosIns.put(`${controller}/change-password`, req).then(res => res.data)
  },
}
